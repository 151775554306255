body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiDialog-paper-1866{
  min-width: 500px !important;
}
.dialog.css-ypiqx9-MuiDialogContent-root {
  overflow: hidden !important;
}

.dialog.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow: hidden !important;
} 
.dialog .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  max-width: 90% !important;
}
.dialog .css-13xfq8m-MuiTabPanel-root {
  padding: 5px !important;
  padding-left: 10px !important;
}

