.App {
  text-align: center;
}

/* .MuiFab-primary-656{
position: fixed !important;
} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .MuiGrid-grid-xs-5-626{
  max-width:100%!important
}
.MuiGrid-container-588{} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pageCount {
  position: absolute;
  right: 57px;
  top: 135px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.PrivateTabIndicator-vertical-1198 {
  width: 100% !important;
  background-color: #b3bfd273 !important;
}


.social-media {

  width: 100px;
  height: 100px;
  position: relative;
  animation-name: social;
  animation-duration: 4s;
}

@keyframes social {
  from {
    left: 1000px;
    top: 0px;
  }

  to {
    left: 0px;
    top: 0px;
  }
}

.facebook {
  animation-timing-function: ease-out;
}

.insta {
  animation-timing-function: ease-in-out;
}

.youtube {
  animation-timing-function: ease-out;
}

.cardShadow {
  box-shadow: -11px 2px 37px #ada9a9;
}


.padstatus {
  width: 80%;
}



/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.mdl-demo .dropdown-trigger>span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #2196f3;
}

.offline-order-card {
  border-bottom: 5px solid rgb(86 100 210);
}

.offline-order-card:hover {
  animation: grow 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  transition: all;
}

.offline-order-view-card:hover {
  animation: grow 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  transition: all;
}



.animate-360-deg:hover {
  animation: animate360 0.6s;
  animation-fill-mode: forwards;

}

.animate-grow:hover .animate-360-deg {
  animation: animate360 0.6s;
  animation-fill-mode: forwards;

}

@keyframes animate360 {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg) scale(1.2);
  }
}


@keyframes grow {
  from {
    transform: scale(1) translateY(0px);
  }

  to {
    transform: scale(1.05) translateY(-6px);
  }
}

.translate-btn:hover {
  animation: translate 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;

}

@keyframes translate {
  from {
    transform: translateY(0px)
  }

  to {
    transform: translateY(-6px)
  }

}

.offline-invoice-header-container {
  background-color: #a3a3a5;
  height: fit-content;
  padding: 10px;
}

.header-address-details-container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.blink-text {
  animation: blink-text 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes blink-text {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }

  /* 50% {opacity: .5;} */
  100% {
    opacity: 1;
    transform: translateY(-410px);
  }
}


#account {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#account td,
#account th {
  border: 1px solid #ddd;
  padding: 8px;
}

#account tr:nth-child(even) {
  background-color: #f2f2f2;
}

#account tr:hover {
  background-color: #ddd;
}

#account th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.videoModeHeading {
  width: 100px;
  text-align: center !important;
}
.MuiDialog-paper-1866{
  min-width: 500px;
}
.cursor-pointer{
  cursor: pointer !important  ;
}
.text-17{
  font-size: 17px;
}
.width-28{
 width: 200px !important;
}